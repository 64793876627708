import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "react-bootstrap";

import imgS1 from "../../assets/image/l3/jpg/l3-service-img1.jpg";
import imgS2 from "../../assets/image/l3/jpg/l3-service-img2.jpg";
import imgS3 from "../../assets/image/l3/jpg/l3-service-img3.jpg";
import icon1 from "../../assets/image/inner/icon-bubbles-blue.svg";
import icon3 from "../../assets/image/inner/calandar-icon.svg";
import HubSpotMeeting from "../../sections/pear-landing/HubSpotMeetingRetail";



const Services = () => {
  return (
    <>
      {/* <!-- Services Area --> */}
      <div className="service-section bg-blue bg-pattern pattern-4 pt-15" id="BookAnAppt">
        <Container>
          <Row className="justify-content-center">
            <Col xl="6" lg="8" md="10">
              <div className="section-title text-center  dark-mode-texts">
                <div className="card-icon ">
                  <div
                    className="circle-78 bg-green text-white gr-text-4 mx-auto mb-9"
                    data-aos="zoom-in"
                    data-aos-duration="800"
                  >
                    <img src={icon3} alt="" className="w-auto" />
                  </div>
                </div>
                <h2 className="title gr-text-4">
                  Book a Meeting
                </h2>
                <p className="gr-text-8 mb-08">
                  with a Pear expert
                </p>
                <HubSpotMeeting />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Services;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgB1 from "../../assets/image/3m-gray.png";
import imgB2 from "../../assets/image/Liquid-Death-Logo.png";
import imgB3 from "../../assets/image/general-mills-gray.png";
import imgB4 from "../../assets/image/perduelogo_b&w.jpg";
import imgB5 from "../../assets/image/johnsonville-gray.png";
import imgB6 from "../../assets/image/dr-teals-log.png";
import imgB7 from "../../assets/image/sc-johnson-logo-1.png";

const Brands = () => {
  const LogoImg = p => (
    <div
      className="single-brand mx-3 py-6 gr-opacity-8 gr-hover-opacity-full col-3 col-lg-2"
      data-aos="zoom-in-right"
      data-aos-duration="500"
    >
      <img
        {...p}
        alt=""
        css={`
          max-height: 75px;
          max-width: 100%;
          opacity: 0.6;
        `}
      />
    </div>
  );
  return (
    <>

      {/* <!-- Brands Area --> */}
      <div className="brand-section border-bottom pb-11  bg-default-6">
        <Container className="border-top pt-10">
          <Row className="justify-content-center align-items-center">
            <Col md="8">
              <p className="gr-text-9 gr-opacity-7 text-center mb-7">
                High quality shoppers, sourced from brands in every category.
              </p>
            </Col>
            <Col xs="12">
              <div className="brand-logos d-flex justify-content-center justify-content-xl-between align-items-center mx-n5 flex-wrap row">

                <LogoImg src={imgB1} className="py-2" />

                <LogoImg src={imgB2} className="ml-6" />

                <LogoImg src={imgB7} className="mr-5" />
                <LogoImg src={imgB4} className="ml-5" />

                <LogoImg src={imgB3} className="py-2" />

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Brands;

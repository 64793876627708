    import React, {useEffect} from "react";
    
    const HubspotContactForm = props => {
        const {} = props;
        useEffect(() => {
            const script = document.createElement('script');
            script.src='https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
            document.body.appendChild(script);
    
        }, []);
    
        return (
            <div>
                <div id="hubspotForm">
                <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/steve-catlin?embed=true"></div>
                </div>
            </div>
        );
    };
    
    export default HubspotContactForm;
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { useForm, ValidationError } from '@formspree/react';

const RetailerContact = (props) => {

  const [state, handleSubmit] = useForm("mlearywe");
  const ResponseMsg = () => {
    if (state.succeeded) {
      return <p>Thanks for reaching out!</p>;
    } else {
      return <p></p>;
    }
  };

  return (
    <>
      {/* <!-- Newsletter section --> */}
      <div
        id="RetailerContact"
        {...props}
        className="newsletter-section pt-13 pb-11 py-lg-25 border-bottom "
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="7" xl="6">
              <div className="section-title text-center mb-11">
                <div
                  className="circle-78 bg-green text-white gr-text-4 mx-auto mb-11"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  <i className="fas fa-envelope"></i>
                </div>
                <h2 className="title gr-text-4 mb-7">Contact Us</h2>
                <p className="px-8 gr-text-8 mb-0">
                  Reach out and grow your e-commerce today!
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xl="6" lg="7" md="8" sm="11">
              <div className="subscribe-form mb-8">
                <form
                  className="cta-form bg-white light-mode rounded-10 px-8 py-8"
                  onSubmit={handleSubmit}
                  id="retail-contact-form"
                  action="https://formspree.io/f/xgernkoq"
                  method="POST"
                  name="website-retail-contact"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="subject" value="Retailer Website Inquiry"></input>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaName">Name </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaName"
                      placeholder="i.e. John Doe"
                      required=""
                      name="name"
                    />
                    <ValidationError
                      prefix="name"
                      field="name"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaEmail">Email </label>
                    <input
                      type="email"
                      className="form-control gr-text-11"
                      id="ctaEmail"
                      placeholder="i.e. john@mail.com"
                      required=""
                      name="_replyto"
                    />
                    <ValidationError
                      prefix="Email"
                      field="_replyto"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                    <label htmlFor="ctaPhone">Phone </label>
                    <input
                      type="text"
                      className="form-control gr-text-11"
                      id="ctaPhone"
                      placeholder="i.e. 123-456-7890"
                    />
                    <ValidationError
                      prefix="Phone"
                      field="phone"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group mb-7">
                    <label
                      htmlFor="message"
                      className="gr-text-11 font-weight-bold text-blackish-blue"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="form-control gr-text-11 border-gray-3 gr-textarea-height"
                      required
                    ></textarea>
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                  </div>
                  <Button type="submit" className="gr-hover-y rounded-8 w-100">
                    Get In Touch
                  </Button>
                  <div className="form-group my-7 text-center">
                    <ResponseMsg />
                  </div>
                </form>
              </div>
              <div className="form-bottom excerpt text-center">
                <p className="gr-text-11 gr-text-color-opacity mb-0">
                  We’ll never share your details with third parties.
                  <br className="d-none d-xs-block" />
                  View our Privacy Policy for more info.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RetailerContact;

import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import target from "../../assets/image/logo-target.png";
import riteAid from "../../assets/image/Rite_Aid-logo.png";
import walmart from "../../assets/image/Walmart-Logo.png";
import petsmart from "../../assets/image/petsmart-logo.png";

const CollageImg = p => (
  <img
    {...p}
    css={`
      width: 175px !important;
      background-size: contain !important;
    `}
    alt=""
  />
);

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section py-20">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="6" className="mb-9 mb-lg-0">
              <div className="content-grid-image-group d-flex  mx-sm-n3">
                <div className="single-image d-flex flex-column pl-3 pl-sm-6">
                  {/* <!-- single image --> */}
                  <CollageImg
                    src={walmart}
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="square-sm pb-6 pb-sm-9 rounded-8"
                    css={`
                      width: 260px !important;
                    `}
                  />
                  {/* <!-- single image --> */}
                  <CollageImg
                    src={riteAid}
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="700"
                    className="square-sm pb-6 pb-sm-9 rounded-8"
                    css={`
                      width: 200px !important;
                    `}
                  />
                </div>
                <div className="single-image d-flex flex-column pt-11 pr-3 pr-sm-6">
                  {/* <!-- single image --> */}
                  <CollageImg
                    src={target}
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="400"
                    className="square-sm pb-6 pb-sm-9 rounded-8"
                  />
                  {/* <!-- single image --> */}
                  <CollageImg
                    src={petsmart}
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="1000"
                    css={`
                      width: 145px !important;
                    `}
                    className="square-sm rounded-8"
                  />
                </div>
              </div>
            </Col>
            <Col sm="10" lg="5" className="col-sm-10 col-lg-5">
              <div className="content-text pl-xl-7">
                <h2 className="gr-text-4 mb-7">
                  A Retailer's Secret Weapon
                </h2>
                <p className="gr-text-8 mb-0">
                  Retailers large and small are taking advantage of CPG brand dollars with Pear Commerce
                  to grow their e-commerce business and gain lifelong customers.
                </p>
                <Link
                  to="#BookAnAppt"
                  className="btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9"
                >
                  Get started{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;

import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/pear-landing/RetailHero";
import RetailerContact from "../sections/pear-landing/RetailerContact";
import BookAnAppt from "../sections/pear-landing/BookAnAppt";
import Brands from "../sections/pear-landing/RetailerBrands";
import Features from "../sections/pear-landing/RetailerFeatures";
import RetailAdoption from "../sections/pear-landing/RetailAdoption";
import ExampleWTB from "../sections/pear-landing/Retailer-Example-WTB";
import ProductLocator from "../sections/pear-landing/Retailer-ProductLocator";

// import Content1 from "../sections/landing6/Content1";
// import Content2 from "../sections/landing6/Content2";
// import Content3 from "../sections/landing6/Content3";
// import Pricing from "../sections/landing6/Pricing";
// import Testimonial from "../sections/landing6/Testimonial";

const WebApplication = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          button: "cta", // cta, account, null
          buttonText: "Get started"
        }}
        footerConfig={{
          theme: "light",
          // style: "style1", //style1, style2
          style: "style5", //style1, style
        }}
      >
        <Hero />
        <Features />
        <Brands />
        <div className="bg-pattern pattern-8 bg-default-2 py-20">
          <RetailAdoption />
          <ExampleWTB />
          <ProductLocator />
        </div>
        {/* <Testimonial /> */}
        <div class="alert-section py-9 bg-secondary dark-mode-texts ">
          <div class="container">
            <div class="justify-content-center row">
              <div class="col-xl-7 col-lg-8 col-md-10 col-sm-9 col-10">
                <div class="alert-content d-flex flex-column flex-lg-row align-items-center text-center justify-content-lg-center">
                  <span class="btn-badge rounded-pill bg-white gr-text-12 text-uppercase font-weight-bold text-primary py-1 px-6 mr-5 mb-6 mb-lg-0 d-inline-flex align-items-center">
                   Easy Install!
                  </span>
                  <span class="alert-text gr-text-9 text-white">
                    Install our Pear Pixel today.{" "}
                    <a
                      class="action-link text-white gr-text-underline"
                      href="/pixel-install"
                    >
                      Takes only 10 minutes!
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookAnAppt/>
        <RetailerContact />
      </PageWrapper>
    </>
  );
};

export default WebApplication;
